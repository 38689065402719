<template>
    <Toast />
    <div class="col-12">
        <div class="card">
            <h5>Imagem de Fundo</h5>
            

            <!-- <Message severity="info" :closable="false">Selecione a imagem que gostaria de aplicar no fundo da tela de login:</Message> -->

            <hr />

            <p>É possível alterar a imagem de fundo da tela de Login do Portal do Cooperado. <strong>(Apenas para colaboradores do Marketing)</strong></p>

            <div class="col-12 flex" >
            <div class="col-6">

                <div>
                    <h5>Imagem de Fundo do Site</h5>
                    <Message severity="info" :closable="false">Tamanho recomendado: 1900 x 1080px</Message>
                    <img :src="`data:image/png;base64,${previewImageSite}`" class="uploading-image" style="max-width: 100%;" /> 
                    <input type="file" accept="image/jpeg" @change=uploadImageSite>
                </div> 

            </div>

            <div class="col-6">
                <div>
                    <h5>Imagem de Fundo do App Celular</h5>
                    <Message severity="info" :closable="false">Tamanho recomendado: 640 x 360px</Message>
                    <img :src="`data:image/png;base64,${previewImageApp}`" class="uploading-image" style="max-width: 100%;" /> 
                    <input type="file" accept="image/jpeg" @change=uploadImageApp>
                </div> 
            </div>
            </div>
            
            <!-- <Fieldset class="mr-2 ml-3">
                <template #legend>
                    <strong>Imagens Anexadas</strong>
                </template>
                
                <div v-if="(anexos.length > 0)" class="grid m-1">                        
                    <div id="anexos" v-for="item in anexos" :key="item.id" @click="confirmaAnexo(item.id)">                         
                        <img v-if="(item.base64 && item.nome != 'default')" v-bind:src="'data:image/jpg;base64,'+ item.base64" />
                        <figcaption v-if="item.base64 && item.nome != 'default'"><i class="pi pi-trash"></i></figcaption>
                    </div>
                </div>
            </Fieldset> -->

            
        </div>
    </div>
</template>

<script lang="js">
    import ImagemFundoService from '../../service/ImagemFundo';
    
    export default {
        name:'ImagemFundo',
        props: {
			matricula: {
				type: Number,
				required: false
			}
		},
        
        data() {
			return {
                //funcionalidades: [],
                previewImageSite : null,
                previewImageApp : null,
                fileUploaded : [],
			}
		},
        
        created() {
            this.carregarImagens();
		},

        methods: {

           async carregarImagens(){
                let Imagem = await ImagemFundoService.recuperarImagemFundo("site");
                this.previewImageSite = Imagem.data.base64;

                let ImagemApp = await ImagemFundoService.recuperarImagemFundo("app");
                this.previewImageApp = ImagemApp.data.base64;
           },

            async uploadImageSite(e){

                const image = e.target.files[0];
                // const reader = new FileReader();
                // reader.readAsDataURL(image);
                // reader.onload = e =>{
                //     this.previewImage = e.target.result;
                //     //console.log(this.previewImage);
                // };
         
                const base64 =  await this.getBase64(image);
                this.imagemFundo = 
                {
                    nome: image.name,
                    base64: base64,
                    destino: "site"
                };
               

                ImagemFundoService.salvarImagemFundo(this.imagemFundo)
                    .then(({ data }) => {
                        if(data) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Sucesso', 
                                detail:'Imagem de fundo do Login foi cadastrada com sucesso!', 
                                life: 9000
                            });  

                            //this.getClassificados();
                        }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Falha', 
                            detail:'Falha ao cadastrar a imagem de fundo do login', 
                            life: 3000
                        });
                    })
                    .finally( ()=> {
                        this.carregarImagens();
                    });
                    

            },

            async uploadImageApp(e){

                const image = e.target.files[0];
                // const reader = new FileReader();
                // reader.readAsDataURL(image);
                // reader.onload = e =>{
                //     this.previewImage = e.target.result;
                //     //console.log(this.previewImage);
                // };

                const base64 =  await this.getBase64(image);
                this.imagemFundo = 
                {
                    nome: image.name,
                    base64: base64,
                    destino: "app"
                };


                ImagemFundoService.salvarImagemFundo(this.imagemFundo)
                    .then(({ data }) => {
                        if(data) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Sucesso', 
                                detail:'Imagem de fundo do Login foi cadastrada com sucesso!', 
                                life: 9000
                            });  

                            //this.getClassificados();
                        }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Falha', 
                            detail:'Falha ao cadastrar a imagem de fundo do login', 
                            life: 3000
                        });
                    })
                    .finally( ()=> {
                        this.carregarImagens();
                    }
                );

            },

            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const result = reader.result;

                        if(typeof result === 'string') {
                            const base64 = result.substring(result.indexOf(',') + 1, result.length);
                            return resolve(base64);
                        }

                        return resolve(result);
                    };
                
                    reader.onerror = function (error) {
                        return reject(error);
                    };

                    reader.readAsDataURL(file);
                });
            },  
            

        }
    }
</script>