<template>
    <ImagemFundo 
        :matricula="currentUser.matricula"
    />
</template>

<script lang="js">
    import ImagemFundo from '../../components/configuracoes/ImagemFundo.vue';

    export  default {
        name: "imagemFundo",
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
	    //emits: ['imagemFundo'],
        components: { ImagemFundo }
    }
</script>